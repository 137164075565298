import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap, TimelineMax } from 'gsap'

import Slide from './Slide'
import { media, getScreenType } from '../../../styles/util'

import RightArrow from '../../../assets/img/arrows/right-carousel-arrow.svg'

import MainTitleAshwagandha from '../../../assets/img/homepage/carousel/main-titles/ashwagandha.svg'
import MainTitleAcv from '../../../assets/img/homepage/carousel/main-titles/acv.svg'
import MainTitleTurmeric from '../../../assets/img/homepage/carousel/main-titles/turmeric.svg'
import MainTitleElderberry from '../../../assets/img/homepage/carousel/main-titles/elderberry.svg'

const fadeIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`

const WhiteFaderHelper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 0;
`

const Section = styled.div`
  position: relative;
  background: #fff;

  width: 100%;
  max-width: 100vw;

  display: grid;
  justify-items: center;
  align-items: center;
  animation: ${fadeIn} 3s ease;

  ${media.tablet} {
    min-height: 100vh;
  }
`

const RightArrowImg = styled.img`
  position: absolute;
  transition: 150ms ease all;
  cursor: pointer;
  z-index: 150;

  &:hover {
    transform: scale(1.3);
  }

  ${media.mobile} {
    right: 20px;
    top: 50%;
  }
  ${media.tablet} {
    right: 40px;
    top: 57%;
  }
  ${media.desktop} {
    top: 50%;
  }
`

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: grid;
  place-items: center;

  ${media.mobile} {
    height: 790px;
  }
  ${media.tablet} {
    height: 100%;
  }
  ${media.desktop} {
    height: 700px;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  z-index: 30;
  ${media.mobile} {
    top: 75px;
    left: 21px;
    width: 62px;
    height: 62px;
  }
  ${media.tablet} {
    top: 45px;
    left: 80px;
    width: 144px;
    height: 144px;
  }
`

const BlobBaseStyling = styled.div`
  opacity: 0;

  position: absolute;
  height: auto;
  z-index: 2;
`

// ASHWAGANDHA

const ImgAshwagandhaBlob1 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 280px;
    bottom: 250px;
    left: -170px;
  }
  ${media.tablet} {
    width: 380px;
    bottom: 230px;
  }
  ${media.desktop} {
    width: 550px;
    bottom: -160px;
    left: -380px;
  }
`

const ImgAshwagandhaBlob2 = styled(BlobBaseStyling)`
  transform: rotate(45deg);

  ${media.mobile} {
    width: 280px;
    top: -150px;
    left: 20%;
  }
  ${media.tablet} {
    width: 350px;
    top: -140px;
    left: 28%;
  }
  ${media.desktop} {
    width: 690px;
    top: -400px;
    left: 25%;
  }
`

const ImgAshwagandhaBlob3 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 210px;
    right: -40px;
    bottom: 280px;
  }
  ${media.tablet} {
    width: 280px;
    right: -40px;
    bottom: 290px;
  }
  ${media.desktop} {
    width: 480px;
    right: -200px;
    bottom: 70px;
  }
`

// const ImgSpirulinaCarryover = styled(BlobBaseStyling)`
//   width: 32vw;
//   right: -18%;
//   bottom: -3%;
// `

// SPIRULINA

// const ImgSpirulinaBlob8 = styled(BlobBaseStyling)`
//   width: 75vw;
//   max-width: 672px;
//   transform: translate(-50%, -50%);
//   left: 45%;
//   top: -60%;
// `

// const ImgSpirulinaBlob9 = styled(BlobBaseStyling)`
//   width: 45vw;
//   max-width: 430px;
//   right: -10%;
//   top: 21%;
// `

// ACV

const ImgAcvBlob2 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 370px;
    left: 4%;
    top: -180px;
  }
  ${media.tablet} {
    width: 500px;
    left: 25%;
    top: -180px;
  }
  ${media.desktop} {
    width: 870px;
    left: 13%;
    top: -450px;
  }
`

const ImgAcvBlob3 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 230px;
    right: -70px;
    top: 38%;
  }
  ${media.tablet} {
    width: 300px;
    top: 42%;
  }
  ${media.desktop} {
    width: 450px;
    right: -140px;
    top: 180px;
  }
`

// TURMERIC

const ImgTurmericBlob4 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 320px;
    left: 7%;
    top: -130px;
  }
  ${media.tablet} {
    width: 500px;
    left: 20%;
    top: -130px;
  }
  ${media.desktop} {
    width: 743px;
    left: 20%;
    top: -290px;
  }
`

const ImgTurmericBlob5 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 220px;
    right: -70px;
    top: 38%;
  }
  ${media.tablet} {
    width: 300px;
  }
  ${media.desktop} {
    width: 430px;
    right: -180px;
    top: 100px;
  }
`

const ImgTurmericBlob6 = styled(BlobBaseStyling)`
  z-index: 3;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 320px;
    right: 40px;
    top: 50%;
  }
  ${media.desktop} {
    width: 480px;
    right: 0;
    top: 260px;
  }
`

// ELDERBERRY

const ImgElderberryBlob1 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 340px;
    left: 8%;
    top: -130px;
  }
  ${media.tablet} {
    width: 600px;
    left: 17%;
    top: -300px;
  }
  ${media.desktop} {
    width: 890px;
    left: 12%;
    top: -500px;
  }
`

const ImgElderberryBlob2 = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 300px;
    bottom: 30%;
    right: -150px;
  }
  ${media.tablet} {
    width: 450px;
    bottom: 17%;
  }
  ${media.desktop} {
    width: 550px;
    bottom: -160px;
    right: -220px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acv: file(relativePath: { regex: "/products/acv/bottle-front.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmeric: file(relativePath: { regex: "/products/turmeric/bottle-front.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    spirulina: file(relativePath: { regex: "/products/spirulina/bottle-front.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ashwagandha: file(relativePath: { regex: "/products/ashwagandha/bottle-front.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberry: file(relativePath: { regex: "/products/elderberry/bottle-front.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ashwagandhaBlob1: file(relativePath: { regex: "/homepage/blobs/ashwagandha-blob-1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ashwagandhaBlob2: file(relativePath: { regex: "/homepage/blobs/ashwagandha-blob-2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ashwagandhaBlob3: file(relativePath: { regex: "/homepage/blobs/ashwagandha-blob-3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acvBlob2: file(relativePath: { regex: "/homepage/blobs/acv-blob-2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acvBlob3: file(relativePath: { regex: "/homepage/blobs/acv-blob-3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmericBlob4: file(relativePath: { regex: "/homepage/blobs/turmeric-blob-4.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmericBlob5: file(relativePath: { regex: "/homepage/blobs/turmeric-blob-5.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmericBlob6: file(relativePath: { regex: "/homepage/blobs/turmeric-blob-6.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberryBlob1: file(relativePath: { regex: "/homepage/blobs/elderberry-blob-1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  const logo = data.logo.childImageSharp.fluid

  const slides = {
    ashwagandha: {
      id: 'ashwagandha',
      header: (
        <img
          src={MainTitleAshwagandha}
          alt="Superfood gummies, but with a little something extra.*"
        />
      ),
      headerExtra: '* the letter r, and real ingredients',
      url: '/ashwagandha',
      img: data.ashwagandha.childImageSharp.fluid,
      productName: 'Ashwagandha',
      productDescription: `Ashwagandha is an Ayurverdic superfood famous for its calming superpowers, but it’s a bitter root to swallow.  We made it delicious, so you'd actually use it.`,
    },
    // spirulina: {
    //   id: 'spirulina',
    //   header: (
    //     <H1>
    //       It doesn't <em>taste</em> healthy
    //     </H1>
    //   ),
    //   url: '/spirulina',
    //   img: data.spirulina.childImageSharp.fluid,
    //   productName: 'Spirulina',
    //   productDescription: `Pure gummies made from high quality apple cider vinegar that still contains “mother” which includes all the enzymes and proteins that make ACV so beneficial to your health.`
    // },
    acv: {
      id: 'acv',
      header: <img src={MainTitleAcv} alt="Tricking you into being healthy" />,
      url: '/apple-cider-vinegar',
      img: data.acv.childImageSharp.fluid,
      productName: 'Apple Cider Vinegar',
      productDescription: `Apple cider vinegar is legendary for its detox and gut benefits, but it’s pretty gross to drink. We made sure our USDA Organic formula tastes grood, not gross.`,
    },
    turmeric: {
      id: 'turmeric',
      header: (
        <img
          src={MainTitleTurmeric}
          alt="Most gummies that taste like candy are actually just candy"
        />
      ),
      url: '/turmeric',
      img: data.turmeric.childImageSharp.fluid,
      productName: 'Turmeric',
      productDescription: `Turmeric’s anti-inflammatory superpowers come from curcuminoids. We use Sabinsa's patented Curcumin C3 Complex, backed by 45+ Clinical Studies.`,
    },
    elderberry: {
      id: 'elderberry',
      header: <img src={MainTitleElderberry} alt="Have you eaten your Grummies today?" />,
      url: '/elderberry',
      img: data.elderberry.childImageSharp.fluid,
      productName: 'Elderberry',
      productDescription: `Elderberry is a potent ancient superfood used across the world for immune health. We made ours USDA Organic with 3x more elderberry than the leading brand.`,
    },
  }

  const startBlobFloat = (className: string) => {
    // Handles "floating" animation for blobs once they have animated in
    return gsap.to(className, {
      duration: 'random(4, 6)',
      stagger: 0.25,
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(16, 24)',
    })
  }

  const timeline = useRef(new TimelineMax({ repeat: -1, repeatDelay: 0, smoothChildTiming: true }))

  const gradientMap: { [key: string]: { [key: string]: string } } = {
    ashwagandha: {
      visible: 'linear-gradient(161.63deg, #99D4FF 8.19%, rgba(255, 255, 255, 0) 67.7%)',
      blank:
        'linear-gradient(161.63deg, rgba(255, 255, 255, 1) 8.19%, rgba(255, 255, 255, 1) 67.7%)', // Blank used the same degrees, but fades to white
      next:
        'linear-gradient(205.24deg, rgba(255, 255, 255, 0) 0.43%, rgba(255, 255, 255, 0) 14.07%, rgba(255, 255, 255, 0) 26.3%, rgba(255, 255, 255, 0) 38.68%)', // Next has degrees matching the next fade in color
    },
    // spirulina: {
    //   color: 'linear-gradient(209.03deg, #838FC5 -3.44%, rgba(126, 243, 157, 0.34) 27.41%, rgba(255, 255, 255, 0) 37.96%)',
    //   blank: 'linear-gradient(209.03deg, #ffffff -3.44%, rgba(255, 255, 255, 0.34) 27.41%, rgba(255, 255, 255, 0) 37.96%)',
    //   next: 'linear-gradient(205.24deg, #ffffff 0.43%, #ffffff 14.07%, #ffffff 26.3%, #ffffff 38.68%)'
    // },
    acv: {
      visible:
        'linear-gradient(205.24deg, #c69eb7 0.43%, #eba59a 14.07%, #f8d8d1 26.3%, #ffffff 38.68%)',
      blank:
        'linear-gradient(205.24deg, rgba(255, 255, 255, 0) 0.43%, rgba(255, 255, 255, 0) 14.07%, rgba(255, 255, 255, 0) 26.3%, rgba(255, 255, 255, 0) 38.68%)',
      next:
        'linear-gradient(145deg, rgba(255, 255, 255, 0) 0.43%, rgba(255, 255, 255, 0) 14.07%, rgba(255, 255, 255, 0) 26.3%, rgba(255, 255, 255, 0) 38.68%)',
    },
    turmeric: {
      visible:
        'linear-gradient(145deg, #c69eb7 0.43%, #eba59a 14.07%, #f8d8d1 26.3%, #ffffff 38.68%)',
      blank:
        'linear-gradient(145deg, rgba(255, 255, 255, 0) 0.43%, rgba(255, 255, 255, 0) 14.07%, rgba(255, 255, 255, 0) 26.3%, rgba(255, 255, 255, 0) 38.68%)',
      next:
        'linear-gradient(161.63deg, rgba(255, 255, 255, 0) 8.19%, rgba(255, 255, 255, 0) 67.7%)',
    },
    elderberry: {
      visible: 'linear-gradient(161.63deg, #99D4FF 8.19%, rgba(255, 255, 255, 0) 67.7%)',
      blank:
        'linear-gradient(161.63deg, rgba(255, 255, 255, 0) 8.19%, rgba(255, 255, 255, 0) 67.7%)',
      next:
        'linear-gradient(161.63deg, rgba(255, 255, 255, 0) 8.19%, rgba(255, 255, 255, 0) 67.7%)',
    },
  }

  const screen = getScreenType()
  const isMobile = screen === 'mobile'
  const isTablet = screen === 'tablet'

  const slideDuration = !isMobile && typeof window !== 'undefined' ? window.innerWidth / 600 : 2
  const enterConfig = { ease: 'expo.out', duration: slideDuration, stagger: 0.15, x: '120vw' }
  const exitConfig = { ease: 'expo.out', duration: 1, stagger: 0.15, x: '-150vw' }

  const animateNextProductIn = (sku: string) => {
    const tl: gsap.core.Timeline = gsap.timeline()
    // Animates new gradient fade into background
    startBlobFloat(`.jumbotron__blob--${sku}`)
    startBlobFloat(`.jumbotron__blob-carryover--${sku}`)
    tl.to(
      '.jumbotron__section-gradient',
      { background: gradientMap[sku].visible, duration: 3 },
      '-=1'
    )
    // then toggles the "slide" with the text/bottle from display: hidden to visible
    tl.to(`.jumbotron__slide--${sku}`, { display: 'grid', duration: 0.1 }, '-=3')
    // then animate in the main bottle and text
    tl.from(`.jumbotron__bottle-image-wrapper--${sku}`, enterConfig, '-=3')
    tl.from(`.jumbotron__textbox--${sku}`, enterConfig, '<0.35')
    // then animates the blobs in
    tl.to(`.jumbotron__blob--${sku}, .jumbotron__blob-carryover--${sku}`, { opacity: 1 }, '-=3')
    tl.from(`.jumbotron__blob--${sku}, .jumbotron__blob-carryover--${sku}`, enterConfig, '-=3')
    return tl
  }

  const animateNextProductOut = (sku: string) => {
    const tl: gsap.core.Timeline = gsap.timeline()
    // If a blob carried over, this grabs the "last" product blob that carried over
    // so that it can be removed too
    let carryoverKey = 'elderberry'
    if (sku === 'acv') carryoverKey = 'ashwagandha'
    if (sku === 'turmeric') carryoverKey = 'acv'
    if (sku === 'elderberry') carryoverKey = 'turmeric'

    // Animate out blob first
    tl.to(`.jumbotron__blob--${sku}`, exitConfig)
    // then bottle
    tl.to(`.jumbotron__bottle-image-wrapper--${sku}`, exitConfig, '<0.15')
    // then the text
    tl.to(`.jumbotron__textbox--${sku}`, exitConfig, '<0.15')
    // then remove that product's slide entirely to make space for the next one
    tl.to(`.jumbotron__slide--${sku}`, { display: 'none' })

    if (carryoverKey) {
      //  and if there is a LEFTOVER "carry-over" blob, anchor it to the left side of the screen
      // for the next product entering
      tl.to(`.jumbotron__blob-carryover--${carryoverKey}`, { ...exitConfig, duration: 1 }, 0)
    }

    let carrOverLeftPosition = isMobile || isTablet ? '-150px' : '-380px'
    if (sku === 'acv') carrOverLeftPosition = isMobile || isTablet ? '-180px' : '-370px'
    if (sku === 'turmeric') carrOverLeftPosition = '-300px'
    if (sku === 'elderberry') carrOverLeftPosition = '-150vw'
    tl.to(`.jumbotron__blob-carryover--${sku}`, { x: 0, left: carrOverLeftPosition }, 0)

    return tl
  }

  /** ---------------- MAIN ANIMATIONS ---------------- */

  const runTimeline = () => {
    const slideOverlap = '-=1.5'
    const slidePauseTime = 6
    const whiteFadeOut = '>-1.25'
    const fadeOutDuration = 1 // How quickly fade out of white to colored gradient
    const whiteFadeIn = '<'
    const fadeInDuration = 0.5 // How quickly fade to white

    // ASHWAGANDHA
    timeline.current.add(animateNextProductIn('ashwagandha'), slideOverlap)
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 0, duration: fadeOutDuration },
      whiteFadeOut
    )
    timeline.current.to('.jumbotron__section-gradient', { duration: slidePauseTime })
    timeline.current.add(animateNextProductOut('ashwagandha'), 'goToAcv')
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 1, duration: fadeInDuration },
      whiteFadeIn
    )

    // ACV
    timeline.current.add(animateNextProductIn('acv'), slideOverlap)
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 0, duration: fadeOutDuration },
      whiteFadeOut
    )
    timeline.current.to('.jumbotron__section-gradient', { duration: slidePauseTime })
    timeline.current.add(animateNextProductOut('acv'), 'goToTurmeric')
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 1, duration: fadeInDuration },
      whiteFadeIn
    )

    // TURMERIC
    timeline.current.add(animateNextProductIn('turmeric'), slideOverlap)
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 0, duration: fadeOutDuration },
      whiteFadeOut
    )
    timeline.current.to('.jumbotron__section-gradient', { duration: slidePauseTime })
    timeline.current.add(animateNextProductOut('turmeric'), 'goToElderberry')
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 1, duration: fadeInDuration },
      whiteFadeIn
    )

    // ELDERBERRY
    timeline.current.add(animateNextProductIn('elderberry'), slideOverlap)
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 0, duration: fadeOutDuration },
      whiteFadeOut
    )
    timeline.current.to('.jumbotron__section-gradient', { duration: slidePauseTime - 2 })
    timeline.current.add(animateNextProductOut('elderberry'), 'goToAshwagandha')
    timeline.current.to(
      '.jumbotron__white-fade-helper-container',
      { opacity: 1, duration: 0.1 },
      whiteFadeIn
    )
  }

  useEffect(() => {
    runTimeline()

    return () => {
      timeline.current.kill()
    }
  }, [])

  const handleNextArrow = () => {
    const nextLabel = timeline.current.nextLabel()
    timeline.current.seek(`${nextLabel}`)
  }

  return (
    <Section className="jumbotron__section-gradient">
      <WhiteFaderHelper className="jumbotron__white-fade-helper-container" />

      <LogoWrapper>
        <Img
          fluid={logo}
          alt="Grummies logo"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </LogoWrapper>
      <RightArrowImg src={RightArrow} alt="Next slide arrow" onClick={handleNextArrow} />

      <RelativeWrapper>
        <Slide slide={slides.ashwagandha} sku="ashwagandha" />
        <ImgAshwagandhaBlob1 className="jumbotron__blob--ashwagandha">
          <Img fluid={data.ashwagandhaBlob1.childImageSharp.fluid} aria-hidden />
        </ImgAshwagandhaBlob1>
        <ImgAshwagandhaBlob2 className="jumbotron__blob--ashwagandha">
          <Img fluid={data.ashwagandhaBlob2.childImageSharp.fluid} aria-hidden />
        </ImgAshwagandhaBlob2>
        <ImgAshwagandhaBlob3 className="jumbotron__blob-carryover--ashwagandha">
          <Img fluid={data.ashwagandhaBlob3.childImageSharp.fluid} aria-hidden />
        </ImgAshwagandhaBlob3>

        <Slide slide={slides.acv} sku="acv" />
        <ImgAcvBlob2 className="jumbotron__blob--acv">
          <Img fluid={data.acvBlob2.childImageSharp.fluid} aria-hidden />
        </ImgAcvBlob2>
        <ImgAcvBlob3 className="jumbotron__blob-carryover--acv">
          <Img fluid={data.acvBlob3.childImageSharp.fluid} aria-hidden />
        </ImgAcvBlob3>

        <Slide slide={slides.turmeric} sku="turmeric" />
        <ImgTurmericBlob4 className="jumbotron__blob--turmeric">
          <Img fluid={data.turmericBlob4.childImageSharp.fluid} aria-hidden />
        </ImgTurmericBlob4>
        <ImgTurmericBlob6 className="jumbotron__blob--turmeric">
          <Img fluid={data.turmericBlob6.childImageSharp.fluid} aria-hidden />
        </ImgTurmericBlob6>
        <ImgTurmericBlob5 className="jumbotron__blob-carryover--turmeric">
          <Img fluid={data.turmericBlob5.childImageSharp.fluid} aria-hidden />
        </ImgTurmericBlob5>

        <Slide slide={slides.elderberry} sku="elderberry" />
        <ImgElderberryBlob1 className="jumbotron__blob--elderberry">
          <Img fluid={data.elderberryBlob1.childImageSharp.fluid} aria-hidden />
        </ImgElderberryBlob1>
        <ImgElderberryBlob2 className="jumbotron__blob-carryover--elderberry">
          <Img fluid={data.ashwagandhaBlob1.childImageSharp.fluid} aria-hidden />
        </ImgElderberryBlob2>
      </RelativeWrapper>
    </Section>
  )
}
