import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { H1 } from '../shared/text'
import { media } from '../../styles/util'

import TextHeader from '../../assets/img/text-headers/nothing-fake-you-cant-pronounce.svg'

const query = graphql`
  query {
    artificialFlavors: file(relativePath: { regex: "/artificial-flavors.png/" }) {
      childImageSharp {
        fluid(maxWidth: 234) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sodiumCarbonate: file(relativePath: { regex: "/sodium-carbonate.png/" }) {
      childImageSharp {
        fluid(maxWidth: 157) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    blueOne: file(relativePath: { regex: "/blue-1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    canolaOil: file(relativePath: { regex: "/canola-oil.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cornSyrup: file(relativePath: { regex: "/corn-syrup.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    dicalciumPhosphate: file(relativePath: { regex: "/dicalcium-phosphate.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    erythritol: file(relativePath: { regex: "/erythritol.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    gelatin: file(relativePath: { regex: "/orbs/gelatin.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    glucoseSyrup: file(relativePath: { regex: "/glucose-syrup.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    artificialSweeteners: file(relativePath: { regex: "/artificial-sweeteners.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    silica: file(relativePath: { regex: "/silica.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stevia: file(relativePath: { regex: "/stevia.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    titaniumDioxide: file(relativePath: { regex: "/titanium-dioxide.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yellowFive: file(relativePath: { regex: "/yellow-5.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const float = keyframes`
  50% { transform: translate(3px, 8px) }
`

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;

  & h1 {
    text-align: center;
    white-space: pre-line;

    ${media.tablet} {
      display: none;
    }
  }
`

const GradientBackground = styled.div`
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(250, 102, 102, 0) 19.76%,
    rgba(250, 102, 102, 0.342) 40.32%,
    rgba(164, 87, 138, 0.234) 54.55%,
    rgba(164, 87, 138, 0) 81.33%
  );

  ${media.tablet} {
    height: 640px;
  }
`

const HeaderImg = styled.img`
  margin: 0 auto;
  width: 100%;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    max-width: 650px;
  }
  ${media.desktop} {
    max-width: 891px;
  }
`

const OrbContainer = styled.div`
  position: relative;

  width: 100%;
  margin: 0 auto;

  ${media.mobile} {
    height: 600px;
    max-width: 500px;
  }
  ${media.desktop} {
    height: 454px;
    max-width: 946px;
  }
`

const BaseOrbWrapper = styled.div`
  position: absolute;
  z-index: 3;

  animation: ${float} 5s ease-out infinite;
`

const DicalciumPhosphate = styled(BaseOrbWrapper)`
  z-index: 5;
  animation-duration: 6s;

  ${media.mobile} {
    top: 0;
    left: 8%;
    width: 120px;
    height: 120px;
  }
  ${media.desktop} {
    top: 4.5%;
    left: 0;
    width: 230px;
    height: 230px;
  }
`

const SodiumCarbonate = styled(BaseOrbWrapper)`
  ${media.mobile} {
    top: 15%;
    left: 40%;
    width: 96px;
    height: 94px;
  }
  ${media.desktop} {
    top: 31.25%;
    left: 17.5%;
    width: 161px;
    height: 157px;
  }
`

const BlueOne = styled(BaseOrbWrapper)`
  animation-duration: 3s;

  ${media.mobile} {
    top: 23%;
    left: 15%;
    width: 48px;
    height: 50px;
  }
  ${media.desktop} {
    top: 58.37%;
    left: 9.39%;
    width: 68px;
    height: 70px;
  }
`

const CornSyrup = styled(BaseOrbWrapper)`
  animation-duration: 8s;

  ${media.mobile} {
    top: 35%;
    left: 33%;
    width: 91px;
    height: 91px;
  }
  ${media.desktop} {
    top: auto;
    bottom: 0;
    left: 15.54%;
    width: 148px;
    height: 148px;
  }
`

const ArtificialSweeteners = styled(BaseOrbWrapper)`
  animation-duration: 4s;

  ${media.mobile} {
    top: 0;
    right: 15%;
    width: 97px;
    height: 97px;
  }
  ${media.desktop} {
    top: 0;
    right: auto;
    left: 32.77%;
    width: 178px;
    height: 181px;
  }
`

const YellowFive = styled(BaseOrbWrapper)`
  animation-duration: 3.7s;

  ${media.mobile} {
    top: 47%;
    left: 54%;
    width: 69px;
    height: 69px;
  }
  ${media.desktop} {
    top: 41.63%;
    left: 35.83%;
    width: 96px;
    height: 96px;
  }
`

const Silica = styled(BaseOrbWrapper)`
  animation-duration: 4s;

  ${media.mobile} {
    top: 30%;
    right: 25%;
    width: 78px;
    height: 78px;
  }
  ${media.desktop} {
    right: auto;
    top: auto;
    bottom: 6.83%;
    left: 33.2%;
    width: 110px;
    height: 110px;
  }
`

const CanolaOil = styled(BaseOrbWrapper)`
  animation-duration: 4s;

  ${media.mobile} {
    bottom: 48%;
    right: 7%;
    width: 62px;
    height: 62px;
  }
  ${media.desktop} {
    bottom: 23.35%;
    left: 45.45%;
    right: auto;
    width: 86px;
    height: 86px;
  }
`

const Erythritol = styled(BaseOrbWrapper)`
  animation-duration: 8s;

  ${media.mobile} {
    bottom: 28%;
    left: 9%;
    height: 132px;
    width: 132px;
  }
  ${media.desktop} {
    bottom: auto;
    top: 24.23%;
    left: auto;
    right: 29%;
    height: 180px;
    width: 180px;
  }
`

const Stevia = styled(BaseOrbWrapper)`
  animation-duration: 4s;
  z-index: 5;

  ${media.mobile} {
    top: 35%;
    left: 8%;
    width: 75px;
    height: 75px;
  }
  ${media.desktop} {
    top: 14.76%;
    left: auto;
    right: 28%;
    width: 86px;
    height: 86px;
  }
`

const ArtificialFlavors = styled(BaseOrbWrapper)`
  animation-duration: 6s;

  ${media.mobile} {
    top: 20%;
    right: 7%;
    width: 72px;
    height: 72px;
  }
  ${media.desktop} {
    top: auto;
    bottom: 2.86%;
    right: 22.2%;
    width: 150px;
    height: 150px;
  }
`

const TitaniumDioxide = styled(BaseOrbWrapper)`
  animation-duration: 10s;
  z-index: 4;

  ${media.mobile} {
    bottom: 20%;
    right: 20%;
    width: 120px;
    height: 120px;
  }
  ${media.desktop} {
    bottom: auto;
    top: 3.2%;
    right: 4.3%;
    width: 225px;
    height: 225px;
  }
`

const Gelatin = styled(BaseOrbWrapper)`
  animation-duration: 4s;

  ${media.mobile} {
    left: 25%;
    bottom: 12%;
    width: 100px;
    height: 100px;
  }
  ${media.desktop} {
    left: auto;
    bottom: 19.16%;
    right: 7.4%;
    width: 140px;
    height: 140px;
  }
`

const GlucoseSyrup = styled(BaseOrbWrapper)`
  animation-duration: 3.5s;
  z-index: 4;

  ${media.mobile} {
    bottom: 33%;
    right: 10%;
    width: 75px;
    height: 75px;
  }
  ${media.desktop} {
    bottom: auto;
    top: 0;
    right: 0;
    width: 120px;
    height: 120px;
  }
`

const NothingFake = () => {
  const data = useStaticQuery(query)

  const dicalciumPhosphate = data.dicalciumPhosphate.childImageSharp.fluid
  const sodiumCarbonate = data.sodiumCarbonate.childImageSharp.fluid
  const blueOne = data.blueOne.childImageSharp.fluid
  const cornSyrup = data.cornSyrup.childImageSharp.fluid
  const artificialSweeteners = data.artificialSweeteners.childImageSharp.fluid
  const yellowFive = data.yellowFive.childImageSharp.fluid
  const silica = data.silica.childImageSharp.fluid
  const canolaOil = data.canolaOil.childImageSharp.fluid
  const erythritol = data.erythritol.childImageSharp.fluid
  const stevia = data.stevia.childImageSharp.fluid
  const artificialFlavors = data.artificialFlavors.childImageSharp.fluid
  const titaniumDioxide = data.titaniumDioxide.childImageSharp.fluid
  const gelatin = data.gelatin.childImageSharp.fluid
  const glucoseSyrup = data.glucoseSyrup.childImageSharp.fluid

  const GatsbyImg = ({ fluid, alt }: { fluid: any; alt: string }) => (
    <Img
      fluid={fluid}
      alt={alt}
      style={{ height: '100%', width: '100%' }}
      imgStyle={{ objectFit: 'contain' }}
    />
  )

  return (
    <Section>
      <HeaderImg src={TextHeader} alt="Nothing fake you cannot pronounce" />
      <H1>{`No fake stuff\nyou can’t pronounce`}</H1>
      <br />
      <GradientBackground>
        <OrbContainer>
          <DicalciumPhosphate>
            <GatsbyImg fluid={dicalciumPhosphate} alt="No dicalcium phosphate" />
          </DicalciumPhosphate>
          <SodiumCarbonate>
            <GatsbyImg fluid={sodiumCarbonate} alt="No sodium carbonate" />
          </SodiumCarbonate>
          <BlueOne>
            <GatsbyImg fluid={blueOne} alt="No blue 1" />
          </BlueOne>
          <CornSyrup>
            <GatsbyImg fluid={cornSyrup} alt="No corm syrup" />
          </CornSyrup>
          <ArtificialSweeteners>
            <GatsbyImg fluid={artificialSweeteners} alt="No artificial sweeteners" />
          </ArtificialSweeteners>
          <YellowFive>
            <GatsbyImg fluid={yellowFive} alt="No yellow 5" />
          </YellowFive>
          <Silica>
            <GatsbyImg fluid={silica} alt="No silica" />
          </Silica>
          <CanolaOil>
            <GatsbyImg fluid={canolaOil} alt="No canola oil" />
          </CanolaOil>
          <Erythritol>
            <GatsbyImg fluid={erythritol} alt="No erythritol" />
          </Erythritol>
          <Stevia>
            <GatsbyImg fluid={stevia} alt="No stevia" />
          </Stevia>
          <ArtificialFlavors>
            <GatsbyImg fluid={artificialFlavors} alt="No artificial flavors" />
          </ArtificialFlavors>
          <TitaniumDioxide>
            <GatsbyImg fluid={titaniumDioxide} alt="No titanium dioxide" />
          </TitaniumDioxide>
          <Gelatin>
            <GatsbyImg fluid={gelatin} alt="No gelatin" />
          </Gelatin>
          <GlucoseSyrup>
            <GatsbyImg fluid={glucoseSyrup} alt="No glucose syrup" />
          </GlucoseSyrup>
        </OrbContainer>
      </GradientBackground>
    </Section>
  )
}

export default NothingFake
