import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { media } from '../../styles/util'

import InstagramTextHeader from '../../assets/img/text-headers/instagram.svg'

const Section = styled.section`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  grid-template-rows: auto 1fr;

  background: linear-gradient(
    180deg,
    rgba(250, 102, 102, 0) 13.94%,
    rgba(250, 102, 102, 0.342) 40.74%,
    rgba(164, 87, 138, 0.234) 57.69%,
    rgba(164, 87, 138, 0) 81.33%
  );

  ${media.mobile} {
    width: 100%;
    grid-row-gap: 40px;
    padding: 80px 0 60px 0;
  }
  ${media.tablet} {
    min-height: 632px;
    width: 100%;
    grid-row-gap: 49px;
    padding: 120px 0 60px 0;
  }
`

const HeaderImg = styled.img`
  width: 100%;

  ${media.mobile} {
    max-width: 150px;
  }
  ${media.tablet} {
    max-width: 350px;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;

  ${media.mobile} {
    height: calc(33vw - 15px);
    grid-template-columns: repeat(3, 1fr);
    padding: 0 14px 0 2px;
    grid-column-gap: 15px;
  }
  ${media.desktop} {
    height: 282px;
    padding: 0;
    grid-template-columns: repeat(3, 282px);
    grid-column-gap: 40px;
    margin-bottom: 120px;
  }
`

const PictureGroup = styled.a`
  width: 100%;
  background-color: #c4c4c4;
  border-radius: 32px;
  box-shadow: 11px 11px 0px #662d8c;
  overflow: hidden;

  &:hover img {
    transform: scale(1.1);
  }
`

const query = graphql`
  query {
    instagram1: file(relativePath: { regex: "/homepage/instagram/image-1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instagram2: file(relativePath: { regex: "/homepage/instagram/image-2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instagram3: file(relativePath: { regex: "/homepage/instagram/image-3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from('.instagram__picture-group', {
      delay: 0.5,
      scrollTrigger: '.instagram__picture-group',
      opacity: 0,
      y: -20,
      stagger: 0.2,
    })
  }, [])

  const pictures = [
    { img: data.instagram1.childImageSharp.fluid, label: 'Ashwagandha gummies' },
    { img: data.instagram2.childImageSharp.fluid, label: 'Model holding Grummies bottle' },
    { img: data.instagram3.childImageSharp.fluid, label: 'Ashwagandha gummies and bottle' },
  ]

  return (
    <Section>
      <HeaderImg src={InstagramTextHeader} alt="Instagram" />
      <Grid>
        {pictures.map(picture => (
          <PictureGroup
            key={picture.label}
            className="instagram__picture-group"
            href="https://www.instagram.com/grummies/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Img fluid={picture.img} alt={picture.label} />
          </PictureGroup>
        ))}
      </Grid>
    </Section>
  )
}
