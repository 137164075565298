import React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../layouts'
import Homepage from '../components/Homepage'
import SEO from '../components/shared/SEO'

const IndexPage: React.FC<PageProps> = props => {
  const { location } = props
  return (
    <>
      <SEO title="Real Superfood Gummies" />
      <Layout location={location}>
        <Homepage />
      </Layout>
    </>
  )
}

export default IndexPage
