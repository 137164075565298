import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Jumbotron from './Jumbotron'
import MadeOf from './MadeOf'
import GroodForYou from './GroodForYou'
import Products from './Products'
import Conspiracy from './Conspiracy'
import Ingredients from './Ingredients'
import NothingFake from './NothingFake'
import Pyramid from './Pyramid'
// import Testimonials from './Testimonials'
import Instagram from './Instagram'
import ImageCarousel from '../shared/ImageCarousel'

import { media } from '../../styles/util'

const Main = styled.main`
  width: 100%;
`

const ImageWrapper = styled.div`
  width: calc(100% - 16px);
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 16px 16px 0px ${props => props.theme.colors.purple};
  overflow: hidden;
  display: grid;
  place-items: center;
  height: auto;

  ${media.mobile} {
    border-radius: 42px;
  }
  ${media.tablet} {
    border-radius: 32px;
  }
`

const DesktopOnly = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 100%;
  }
`

const MobileOnly = styled.div`
  width: 100%;

  ${media.tablet} {
    display: none;
  }
`

const query = graphql`
  query {
    image1: file(relativePath: { regex: "/homepage/carousel/image-1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image2: file(relativePath: { regex: "/homepage/carousel/image-2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image3: file(relativePath: { regex: "/homepage/carousel/image-3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image4: file(relativePath: { regex: "/homepage/carousel/image-4.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image5: file(relativePath: { regex: "/homepage/carousel/image-5.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image1mobile: file(relativePath: { regex: "/homepage/carousel/image-1-mobile.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image2mobile: file(relativePath: { regex: "/homepage/carousel/image-2-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image3mobile: file(relativePath: { regex: "/homepage/carousel/image-3-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image4mobile: file(relativePath: { regex: "/homepage/carousel/image-4-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image5mobile: file(relativePath: { regex: "/homepage/carousel/image-5-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    madeInUsaBadge: file(relativePath: { regex: "/ingredients/badges/made-in-usa.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    igenBadge: file(relativePath: { regex: "/ingredients/badges/igen.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    usdaBadge: file(relativePath: { regex: "/ingredients/badges/organic-sweeteners.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    labTestedBadge: file(relativePath: { regex: "/ingredients/badges/lab-tested.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    veganBadge: file(relativePath: { regex: "/ingredients/badges/vegan.png/" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const images = useStaticQuery(query)

  const carouselImages = [
    {
      desktop: images.image1.childImageSharp.fluid,
      mobile: images.image1mobile.childImageSharp.fluid,
      alt: 'Apple Cider Vinegar grummies',
    },
    {
      desktop: images.image2.childImageSharp.fluid,
      mobile: images.image2mobile.childImageSharp.fluid,
      alt: 'Grummies bottles on side',
    },
    {
      desktop: images.image3.childImageSharp.fluid,
      mobile: images.image3mobile.childImageSharp.fluid,
      alt: 'Grummies bottles standing up',
    },
    {
      desktop: images.image4.childImageSharp.fluid,
      mobile: images.image4mobile.childImageSharp.fluid,
      alt: 'Ashwagandha gummies',
    },
    {
      desktop: images.image5.childImageSharp.fluid,
      mobile: images.image5mobile.childImageSharp.fluid,
      alt: 'Woman holding Grummies',
    },
  ]

  const madeOfBadges = [
    { img: images.veganBadge.childImageSharp.fluid, alt: 'Vegan badge' },
    { img: images.igenBadge.childImageSharp.fluid, alt: 'IGEN Non-GMO Tested badge' },
    { img: images.usdaBadge.childImageSharp.fluid, alt: 'USDA Organic badge' },
    { img: images.labTestedBadge.childImageSharp.fluid, alt: 'Lab Tested badge' },
    { img: images.madeInUsaBadge.childImageSharp.fluid, alt: 'Made in USA badge' },
  ]

  return (
    <Main>
      <Jumbotron />
      <MadeOf badges={madeOfBadges} />
      <GroodForYou />
      <Products />
      <Conspiracy />
      <Ingredients />
      <NothingFake />
      <DesktopOnly>
        <ImageCarousel>
          {carouselImages.map(image => (
            <ImageWrapper key={image.alt}>
              <Img
                fluid={image.desktop}
                alt={image.alt}
                style={{ height: '100%', width: '100%' }}
              />
            </ImageWrapper>
          ))}
        </ImageCarousel>
      </DesktopOnly>
      <MobileOnly>
        <ImageCarousel>
          {carouselImages.map(image => (
            <ImageWrapper key={image.alt}>
              <Img fluid={image.mobile} alt={image.alt} style={{ height: '100%', width: '100%' }} />
            </ImageWrapper>
          ))}
        </ImageCarousel>
      </MobileOnly>
      <Pyramid />
      {/* <Testimonials /> */}
      <Instagram />
    </Main>
  )
}
