import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { P } from '../../shared/text'
import { media } from '../../../styles/util'

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  grid-auto-rows: 186px;
  grid-column-gap: 24px;
  padding: 0 10px;
  margin-top: 20px;

  ${media.desktop} {
    display: none;
  }
`

const IngredientGroup = styled.div`
  border-top: 2px solid ${props => props.theme.colors.purple};
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 30px;
  padding: 10px 0;

  ${media.mobile} {
    & p {
      font-size: 12px;
    }
  }
  ${media.tablet} {
    & p {
      font-size: 16px;
    }
  }

  &:last-of-type {
    border-bottom: 2px solid ${props => props.theme.colors.purple};
  }
  &:nth-last-of-type(3) {
    border-bottom: 2px solid ${props => props.theme.colors.purple};
  }
`

const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 82px;
  max-width: 114px;
`

const Divider = styled.div`
  width: 2px;
  height: calc(100% - 24px);
  max-height: 150px;
  background-color: ${props => props.theme.colors.purple};
  margin: auto 0;
`

interface GatsbyImgInterface {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface IngredientInterface {
  img: GatsbyImgInterface
  label: string
}

export default ({ ingredients }: { ingredients: IngredientInterface[] }) => {
  const IngredientRow = ({ from, to }: { from: number; to: number }) => (
    <>
      {ingredients.slice(from, to).map((ingredient, idx) => (
        <React.Fragment key={ingredient.label}>
          <IngredientGroup>
            <ImgWrapper>
              <Img
                fluid={ingredient.img.childImageSharp.fluid}
                alt={ingredient.label}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImgWrapper>
            <P>{ingredient.label}</P>
          </IngredientGroup>
          {idx === 0 && <Divider />}
        </React.Fragment>
      ))}
    </>
  )

  return (
    <Grid>
      <IngredientRow from={0} to={2} />
      <IngredientRow from={2} to={4} />
      <IngredientRow from={4} to={6} />
    </Grid>
  )
}
