import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { P } from '../../shared/text'
import { media } from '../../../styles/util'

const Grid = styled.div`
  width: 100%;
  grid-auto-rows: 334px;
  grid-column-gap: 24px;
  padding: 0 45px;

  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    margin-top: 20px;
  }
`

const IngredientGroup = styled.div`
  border-top: 2px solid ${props => props.theme.colors.purple};
  display: grid;
  place-items: center;
  grid-template-rows: 1fr 56px;
  padding: 20px;
  &:nth-of-type(n + 6) {
    border-bottom: 1px solid ${props => props.theme.colors.purple};
  }
`

const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 183px;
  max-width: 228px;
`

const DesktopDivider = styled.div`
  width: 2px;
  height: calc(100% - 24px);
  max-height: 300px;
  background-color: ${props => props.theme.colors.purple};
  margin: auto 0;

  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
  }
`
interface GatsbyImgInterface {
  childImageSharp: {
    fluid: {
      aspectRatio: number
      sizes: string
      src: string
      srcSet: string
    }
  }
}

interface IngredientInterface {
  img: GatsbyImgInterface
  label: string
}

export default ({ ingredients }: { ingredients: IngredientInterface[] }) => {
  return (
    <Grid>
      {ingredients.slice(0, 2).map(ingredient => (
        <React.Fragment key={ingredient.label}>
          <IngredientGroup>
            <ImgWrapper>
              <Img
                fluid={ingredient.img.childImageSharp.fluid}
                alt={ingredient.label}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImgWrapper>
            <P>{ingredient.label}</P>
          </IngredientGroup>
          <DesktopDivider />
        </React.Fragment>
      ))}
      <IngredientGroup key={ingredients[2].label}>
        <ImgWrapper>
          <Img
            fluid={ingredients[2].img.childImageSharp.fluid}
            alt={ingredients[2].label}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImgWrapper>
        <p>{ingredients[2].label}</p>
      </IngredientGroup>
      {ingredients.slice(3, 5).map(ingredient => (
        <React.Fragment key={ingredient.label}>
          <IngredientGroup>
            <ImgWrapper>
              <Img
                fluid={ingredient.img.childImageSharp.fluid}
                alt={ingredient.label}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </ImgWrapper>
            <p>{ingredient.label}</p>
          </IngredientGroup>
          <DesktopDivider />
        </React.Fragment>
      ))}
      <IngredientGroup key={ingredients[5].label}>
        <ImgWrapper>
          <Img
            fluid={ingredients[5].img.childImageSharp.fluid}
            alt={ingredients[5].label}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImgWrapper>
        <p>{ingredients[5].label}</p>
      </IngredientGroup>
    </Grid>
  )
}
