import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { H2, P } from '../../shared/text'
import { CtaButton } from '../../shared/buttons'
import { media } from '../../../styles/util'

const SlideContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  display: none;
  z-index: 10;

  justify-items: center;
  align-items: end;
  width: 100%;
  max-width: 1200px;
  height: 100%;
`

const ContentWrapper = styled.div`
  margin: 0 auto 0 auto;
  height: fit-content;
  display: grid;
  justify-content: center;

  ${media.mobile} {
    justify-items: center;
    align-items: center;
    padding: 150px 20px 50px 20px;
  }
  ${media.tablet} {
    padding: 40px 20px 50px 20px;
  }
  ${media.desktop} {
    grid-template-columns: auto minmax(auto, 368px);
    grid-column-gap: 20px;
    align-items: end;
    padding: 0 20px 54px 20px;
  }
`

const TextBox = styled.div`
  display: grid;

  ${media.mobile} {
    grid-row-gap: 24px;
    text-align: center;
    max-width: 335px;
    grid-template-rows: 110px auto auto auto;

    & > img {
      max-height: 113px;
    }

    & h1 {
      text-align: left;
      justify-self: center;
    }
  }
  ${media.tablet} {
    max-width: 650px;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 40px;

    & > img {
      max-height: none;
    }
  }
  ${media.desktop} {
    width: fit-content;
    max-width: 700px;
    grid-row-gap: 26px;
    text-align: left;
    justify-self: end;
    margin-bottom: 24px;

    & h1 {
      justify-self: initial;
    }

    & p {
      max-width: 530px;
    }
  }
`

const ExtraText = styled(H2)`
  text-align: left;
  ${media.mobile} {
    font-size: 20px;
    margin-top: -16px;
    line-height: 1.2;
  }
  ${media.desktop} {
    font-size: 32px;
    margin-top: -10px;
    line-height: 1.2;
  }
`

const BottleImgWrapper = styled(Link)`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
    width: 100%;

    &:hover {
      & > div {
        transition: 200ms ease transform;
        transform: scale(1.03);
      }
    }
  }
`

const MobileBottleImgWrapper = styled(Link)`
  ${media.mobile} {
    height: 211px;
    width: 200px;
    margin: 0 auto;
  }
  ${media.tablet} {
    height: 280px;
  }
  ${media.desktop} {
    display: none;
  }
`

interface SlideDetailInterface {
  id: string
  header: React.ReactNode
  headerExtra?: string
  url: string
  productName: string
  productDescription: string
  img: {
    aspectRatio: number
    sizes: string
    src: string
    srcSet: string
  }
}

interface SlideInterface {
  slide: SlideDetailInterface
  sku: string
}

const Slide: React.FC<SlideInterface> = ({ slide, sku }) => {
  return (
    <SlideContainer className={`jumbotron__slide--${sku}`}>
      <ContentWrapper>
        <TextBox className={`jumbotron__textbox--${sku}`}>
          {slide.header}
          <ExtraText>{slide.headerExtra || ''}</ExtraText>
          <MobileBottleImgWrapper
            to={slide.url}
            className={`jumbotron__bottle-image-wrapper--${sku}`}
          >
            <Img
              fluid={slide.img}
              alt={slide.productName}
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </MobileBottleImgWrapper>
          <Link to={slide.url}>
            <CtaButton>Shop {slide.productName}</CtaButton>
          </Link>
          <P>{slide.productDescription}</P>
        </TextBox>
        <BottleImgWrapper className={`jumbotron__bottle-image-wrapper--${sku}`} to={slide.url}>
          <Img
            fluid={slide.img}
            alt={slide.productName}
            style={{ height: '100%', width: '100%', maxHeight: 500 }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </BottleImgWrapper>
      </ContentWrapper>
    </SlideContainer>
  )
}

export default React.memo(Slide)
