import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Img from 'gatsby-image'

import { media } from '../../styles/util'

import Arrow from '../../assets/img/arrows/made-of-arrow.svg'

import Paragraph1 from '../../assets/img/homepage/made-of/paragraph-1.svg'
import Paragraph2 from '../../assets/img/homepage/made-of/paragraph-2.svg'
import Paragraph3 from '../../assets/img/homepage/made-of/paragraph-3.svg'
import Paragraph4 from '../../assets/img/homepage/made-of/paragraph-4.svg'
import Paragraph1Mobile from '../../assets/img/homepage/made-of/paragraph-1-mobile.svg'
import Paragraph2Mobile from '../../assets/img/homepage/made-of/paragraph-2-mobile.svg'
import Paragraph3Mobile from '../../assets/img/homepage/made-of/paragraph-3-mobile.svg'
import Paragraph4Mobile from '../../assets/img/homepage/made-of/paragraph-4-mobile.svg'

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  display: grid;
  ${media.mobile} {
    padding: 0 10px;
    margin: 40px auto 31px auto;
    overflow-x: hidden;
    grid-row-gap: 40px;
  }
  ${media.tablet} {
    grid-row-gap: 32px;
    overflow-x: initial;
  }
  ${media.desktop} {
    padding: 0 40px;
    max-width: 1032px;
    margin: 100px auto 119px auto;
  }
`

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile} {
    padding: 0 0 150px 0;
  }
  ${media.desktop} {
    padding: 10px 0 233px 0;
  }
`

const BadgeGrid = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(5, 1fr);
  ${media.mobile} {
    grid-column-gap: 10px;
  }
  ${media.tablet} {
    padding: 0 24px;
    grid-column-gap: 20px;
  }
`

const BadgeImg = styled.div`
  height: 100%;
  width: 100%;
  z-index: 3;

  ${media.mobile} {
    max-height: 55px;
  }
  ${media.tablet} {
    max-width: 63px;
    max-height: 63px;
  }
`

const ArrowImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  ${media.mobile} {
    top: 60px;
    min-width: 150vw;
    max-height: 165px;
  }
  ${media.tablet} {
    top: 0;
    width: 96vw;
    min-width: initial;
    max-height: none;
  }
  ${media.desktop} {
    min-height: 250px;
    max-width: 1132px;
  }
`

const MobileText = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  place-items: center;

  & img {
    width: 280px;
  }

  & img:first-of-type {
    width: 255px;
  }

  ${media.tablet} {
    display: none;
  }
`

const DesktopText = styled.div`
  width: 100%;
  grid-row-gap: 30px;
  place-items: center;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: grid;
  }
`

interface PropsInterface {
  badges: {
    img: {
      src: string
      srcSet: string
      sizes: string
      aspectRatio: number
    }
    alt: string
  }[]
}

export default ({ badges }: PropsInterface) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.from('.made-of__paragraph-copy', {
      delay: 0.25,
      scrollTrigger: '.made-of__paragraph-copy',
      y: 20,
      opacity: 0,
      stagger: 0.25,
    })
  }, [])

  return (
    <Section>
      <RelativeWrapper>
        <BadgeGrid>
          {badges.map(badge => (
            <BadgeImg key={badge.alt}>
              <Img
                fluid={badge.img}
                alt={badge.alt}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </BadgeImg>
          ))}
        </BadgeGrid>
        <ArrowImg src={Arrow} aria-hidden />
      </RelativeWrapper>

      <DesktopText>
        <img
          className="made-of__paragraph-copy"
          src={Paragraph1}
          alt="Gummies are full of fake things.  Don't pretend you haven't always known."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph2}
          alt="That's why we made Grummies. They're like gummies, but with the letter R, for Real ingredients."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph3}
          alt="We spent two years working with the best scientific minds, taking all the bad stuff out of gummy vitamins without sacrificing any of the taste or health benefits."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph4}
          alt="Grummies feature real superfoods. Organic sweeteners. Flavors made from actual fruit. Crazy good gummy flavor. No fake stuff you can't pronounce."
        />
      </DesktopText>

      <MobileText>
        <img
          className="made-of__paragraph-copy"
          src={Paragraph1Mobile}
          alt="Gummies are full of fake things.  Don't pretend you haven't always known."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph2Mobile}
          alt="That's why we made Grummies. They're like gummies, but with the letter R, for Real ingredients."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph3Mobile}
          alt="We spent two years working with the best scientific minds, taking all the bad stuff out of gummy vitamins without sacrificing any of the taste or health benefits."
        />
        <img
          className="made-of__paragraph-copy"
          src={Paragraph4Mobile}
          alt="Grummies feature real superfoods. Organic sweeteners. Flavors made from actual fruit. Crazy good gummy flavor. No fake stuff you can't pronounce."
        />
      </MobileText>
    </Section>
  )
}
