import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../../styles/util'

import TextHeader from '../../assets/img/text-headers/grummies-are-a-frood-group.svg'

const float = keyframes`
  50% { transform: translate(3px, 24px) }
`

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile} {
    margin-top: 30px;
  }
  ${media.tablet} {
    margin-top: 120px;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  & > div:first-of-type {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  ${media.mobile} {
    margin-top: -75px;
    width: 100%;
  }
  ${media.tablet} {
    margin-top: -100px;
    height: 430px;
    width: 700px;
  }
  ${media.desktop} {
    width: 935px;
    height: 545px;
    margin-top: -110px;
  }
`

const HeaderImg = styled.img`
  width: 100%
  margin: 0 auto;

  ${media.mobile} {
    max-width: 345px;
  }
  ${media.tablet} {
    max-width: 580px;
  }
`

const BaseOrbWrapper = styled.div`
  position: absolute;
  z-index: 3;

  animation: ${float} 5s ease-out infinite;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const TopLeftBlueGreen = styled(BaseOrbWrapper)`
  top: 30%;
  left: 4.5%;
  height: 59px;
  width: 62px;
  z-index: 4;
`

const TopLeftPurple = styled(BaseOrbWrapper)`
  top: 36%;
  left: -20px;
  height: 99px;
  width: 102px;
  animation-duration: 7s;
`

const BottomLeftBlue = styled(BaseOrbWrapper)`
  bottom: 19.7%;
  left: 17.22%;
  height: 83px;
  width: 84px;
  animation-duration: 6s;
`

const BottomGreenBlue = styled(BaseOrbWrapper)`
  bottom: 8%;
  left: 52.3%;
  height: 59px;
  width: 62px;
  animation-duration: 5s;
`

const RightGreenBlue = styled(BaseOrbWrapper)`
  bottom: 34%;
  right: 7.75%;
  height: 101px;
  width: 102px;
  animation-duration: 9s;
`

const RightPurple = styled(BaseOrbWrapper)`
  bottom: 46%;
  right: 6.2%;
  height: 85px;
  width: 86px;
  animation-duration: 6s;
  z-index: 4;
`

const RightYellow = styled(BaseOrbWrapper)`
  top: -2%;
  right: 2.9%;
  height: 85px;
  width: 86px;
  animation-duration: 8s;

  ${media.tablet} {
    display: none;
  }
  ${media.desktop} {
    display: block;
  }
`

const MobileOnly = styled.div`
  z-index: 5;
  ${media.tablet} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    height: 100%;
    width: 100%;
    z-index: 3;
  }
`

const query = graphql`
  query {
    pyramid: file(relativePath: { regex: "/homepage/pyramid/pyramid.png/" }) {
      childImageSharp {
        fluid(maxWidth: 935) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pyramidMobile: file(relativePath: { regex: "/homepage/pyramid/pyramid-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    topLeftBlueGreen: file(relativePath: { regex: "/pyramid/top-left-blue-green.png/" }) {
      childImageSharp {
        fluid(maxWidth: 65) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    topLeftPurple: file(relativePath: { regex: "/pyramid/top-left-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 102) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomLeftBlue: file(relativePath: { regex: "/pyramid/bottom-left-blue.png/" }) {
      childImageSharp {
        fluid(maxWidth: 84) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bottomGreenBlue: file(relativePath: { regex: "/pyramid/bottom-green-blue.png/" }) {
      childImageSharp {
        fluid(maxWidth: 62) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rightGreenBlue: file(relativePath: { regex: "/pyramid/right-green-blue.png/" }) {
      childImageSharp {
        fluid(maxWidth: 102) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rightPurple: file(relativePath: { regex: "/pyramid/right-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 86) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rightYellow: file(relativePath: { regex: "/pyramid/right-yellow.png/" }) {
      childImageSharp {
        fluid(maxWidth: 86) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface GatsbyFluid {
  aspectRatio: number
  sizes: string
  src: string
  srcSet: string
}

export default () => {
  const data = useStaticQuery(query)

  const pyramidImg = data.pyramid.childImageSharp.fluid
  const pyramidMobile = data.pyramidMobile.childImageSharp.fluid
  const topLeftBlueGreen = data.topLeftBlueGreen.childImageSharp.fluid
  const topLeftPurple = data.topLeftPurple.childImageSharp.fluid
  const bottomLeftBlue = data.bottomLeftBlue.childImageSharp.fluid
  const bottomGreenBlue = data.bottomGreenBlue.childImageSharp.fluid
  const rightGreenBlue = data.rightGreenBlue.childImageSharp.fluid
  const rightPurple = data.rightPurple.childImageSharp.fluid
  const rightYellow = data.rightYellow.childImageSharp.fluid

  const GatsbyImg = ({ fluid }: { fluid: GatsbyFluid }) => (
    <Img
      fluid={fluid}
      aria-hidden
      style={{ height: '100%', width: '100%' }}
      imgStyle={{ objectFit: 'contain' }}
    />
  )

  return (
    <Section>
      <HeaderImg src={TextHeader} alt="Grummies are a frood group" />
      <ImageWrapper>
        <DesktopOnly>
          <Img
            fluid={pyramidImg}
            alt="Food pyramid"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </DesktopOnly>
        <MobileOnly>
          <Img
            fluid={pyramidMobile}
            alt="Food pyramid"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </MobileOnly>
        <TopLeftBlueGreen>
          <GatsbyImg fluid={topLeftBlueGreen} />
        </TopLeftBlueGreen>
        <TopLeftPurple>
          <GatsbyImg fluid={topLeftPurple} />
        </TopLeftPurple>
        <BottomLeftBlue>
          <GatsbyImg fluid={bottomLeftBlue} />
        </BottomLeftBlue>
        <BottomGreenBlue>
          <GatsbyImg fluid={bottomGreenBlue} />
        </BottomGreenBlue>
        <RightGreenBlue>
          <GatsbyImg fluid={rightGreenBlue} />
        </RightGreenBlue>
        <RightPurple>
          <GatsbyImg fluid={rightPurple} />
        </RightPurple>
        <RightYellow>
          <GatsbyImg fluid={rightYellow} />
        </RightYellow>
      </ImageWrapper>
    </Section>
  )
}
