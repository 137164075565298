import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import DesktopIngredientGrid from './DesktopIngredientGrid'
import MobileIngredientGrid from './MobileIngredientGrid'
import { LargeP, P } from '../../shared/text'
import { media } from '../../../styles/util'

import RealSuperFoodsHeader from '../../../assets/img/text-headers/real-superfoods.svg'

import NoSoy from '../../../assets/img/ingredients/doesnt-include/no-soy.png'
import NoPeanuts from '../../../assets/img/ingredients/doesnt-include/no-peanuts.png'
import NoTreeNuts from '../../../assets/img/ingredients/doesnt-include/no-tree-nuts.png'
import NoGelatin from '../../../assets/img/ingredients/doesnt-include/no-gelatin.png'
import NoDairy from '../../../assets/img/ingredients/doesnt-include/no-dairy.png'
import GlutenFree from '../../../assets/img/ingredients/doesnt-include/no-gluten.png'
import NonGmo from '../../../assets/img/ingredients/doesnt-include/non-gmo.png'

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  grid-row-gap: 20px;

  ${media.mobile} {
    margin: 82px auto 60px auto;
    & > p {
      max-width: 90vw;
    }
  }
  ${media.tablet} {
    margin: 82px auto 160px auto;
    & > p {
      max-width: 900px;
      padding: 0 45px;
    }
  }
`

const HeaderImg = styled.img`
  width: 100%;
  margin-bottom: 30px;

  ${media.mobile} {
    max-width: 225px;
  }
  ${media.tablet} {
    max-width: 350px !important;
  }
`

const DoesntIncludeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  ${media.mobile} {
    padding: 0 14px;
    max-width: 330px;
  }
  ${media.tablet} {
    padding: 0 10px;
    max-width: none;
  }
`

const DoesntIncludeGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  place-items: center;
  grid-column-gap: 6px;

  ${media.mobile} {
    & p {
      font-size: 12px;
    }
  }
  ${media.tablet} {
    & p {
      font-size: 16px;
    }
  }
`

const DoesntIncludeImg = styled.img`
  ${media.mobile} {
    width: 43px;
  }
  ${media.tablet} {
    width: 68px;
  }
`

const query = graphql`
  query {
    ashwagandha: file(relativePath: { regex: "/ingredients/ashwagandha.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    citrus: file(relativePath: { regex: "/ingredients/citrus.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acv: file(relativePath: { regex: "/ingredients/acv.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ginger: file(relativePath: { regex: "/ingredients/ginger.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberry: file(relativePath: { regex: "/ingredients/elderberry.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    turmeric: file(relativePath: { regex: "/ingredients/turmeric.png/" }) {
      childImageSharp {
        fluid(maxWidth: 228) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from('.ingredients--enter-animation', {
      delay: 0.25,
      scrollTrigger: '.ingredients--enter-animation',
      opacity: 0,
      y: 20,
      duration: 1,
      stagger: 0.5,
    })
  }, [])

  const ingredients = [
    { img: data.ashwagandha, label: '1. Ashwagandha' },
    { img: data.elderberry, label: '2. Elderberry' },
    { img: data.acv, label: '3. Apple Cider Vinegar' },
    { img: data.turmeric, label: '4. Turmeric' },
    { img: data.citrus, label: '5. Citrus' },
    { img: data.ginger, label: '6. Ginger' },
  ]

  const doesntInclude = [
    { img: NoSoy, label: '1. No soy' },
    { img: NoPeanuts, label: '2. No peanuts' },
    { img: NoTreeNuts, label: '3. No tree nuts' },
    { img: NoGelatin, label: '4. No gelatin' },
    { img: NoDairy, label: '5. No dairy' },
    { img: GlutenFree, label: '6. Gluten free' },
    { img: NonGmo, label: '7. Non-GMO' },
  ]

  return (
    <Section>
      <HeaderImg
        src={RealSuperFoodsHeader}
        alt="Real Superfoods"
        className="ingredients--enter-animation"
      />
      <LargeP className="ingredients--enter-animation">
        Finding the right stuff to put in your body requires homework (gross). That’s why we did
        tons of research with the best R&D teams. Grummies feature non-GMO, vegan superfoods in the
        highest available gummy dosages.
      </LargeP>
      <DesktopIngredientGrid ingredients={ingredients} />
      <MobileIngredientGrid ingredients={ingredients} />
      <DoesntIncludeContainer>
        {doesntInclude.map(ingredient => (
          <DoesntIncludeGroup key={ingredient.label}>
            <P>{ingredient.label}</P>
            <DoesntIncludeImg src={ingredient.img} alt={ingredient.label} />
          </DoesntIncludeGroup>
        ))}
      </DoesntIncludeContainer>
    </Section>
  )
}
