import React, { useContext, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Spinner from '../shared/Spinner'
import { H1, H2 } from '../shared/text'
import { Button } from '../shared/buttons'
import { media } from '../../styles/util'

import StoreContext from '../../context/StoreContext'

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  display: grid;

  ${media.mobile} {
    grid-row-gap: 40px;
    margin: 36px auto 58px auto;
  }
  ${media.desktop} {
    grid-row-gap: 60px;
    margin: 82px auto 125px auto;
  }
`

const ProductsContainer = styled.div`
  display: grid;
  place-items: center;

  ${media.mobile} {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;
    grid-gap: 33px 6px;
  }

  ${media.tablet} {
    width: fit-content;
    padding: 0 30px;
    grid-gap: 60px;
  }

  ${media.desktop} {
    width: 100%;
    max-width: 1200px;
    min-height: 376px;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 27px;
    grid-gap: 30px;
  }
`

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.02); }
  70% { transform: rotate(-2deg) scale(1.02); }
  100% { transform: rotate(0deg); }
`

const ProductGroup = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  ${media.mobile} {
    grid-template-rows: 222px 1fr auto;
    grid-row-gap: 12px;

    // &:last-of-type {
    //   grid-column: 1 / span 2;
    // }
  }
  ${media.tablet} {
    grid-template-rows: 268px 1fr 1fr;
    grid-row-gap: 16px;
    // &:last-of-type {
    //   grid-column: initial;
    // }
  }

  & button {
    background-color: ${props => props.theme.colors.purple};
    color: #fff;

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.purple};
    }
  }
`

const ImageLink = styled(Link)`
  width: 100%;

  ${media.mobile} {
    height: 200px;
  }
  ${media.desktop} {
    height: 272px;
  }

  &:hover {
    animation: ${wiggle} 250ms;
  }
`

export default () => {
  const { loading, products: productMap } = useContext(StoreContext)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.from('.homepage-products__product-group', {
      delay: 0.5,
      scrollTrigger: '.homepage-products__product-group',
      opacity: 0,
      stagger: 0.2,
    })
  }, [loading])

  const skus = ['ASHWAGANDHA', 'ELDERBERRY', 'ACV', 'TURMERIC']

  return (
    <Section>
      <H1>Meet the team</H1>
      <ProductsContainer>
        {loading ? (
          <ProductGroup className="homepage-products__product-group">
            <Spinner color={props => props.theme.colors.purple} opacity={1} />
          </ProductGroup>
        ) : (
          skus.map(sku => {
            const detail = productMap.byProduct[sku]
            if (!detail) return null
            const product = detail.jsonData

            return (
              <ProductGroup key={product.shortTitle} className="homepage-products__product-group">
                <ImageLink to={`/${product.slug}`}>
                  <Img
                    fluid={product.images.bottles.single.childImageSharp.fluid}
                    alt={product.shortTitle}
                    style={{ height: '100%', width: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </ImageLink>
                <Link to={`/${product.slug}`}>
                  <H2>{product.shortTitle}</H2>
                </Link>
                <Link to={`/${product.slug}`}>
                  <Button>
                    <H2>Shop Now</H2>
                  </Button>
                </Link>
              </ProductGroup>
            )
          })
        )}
      </ProductsContainer>
    </Section>
  )
}
