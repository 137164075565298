import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { media } from '../../styles/util'

import Arrow from '../../assets/img/arrows/conspiracy-arrow.svg'
import WideSpreadConspiracyDesktop from '../../assets/img/text-headers/widespread-conspiracy-desktop.svg'
import WideSpreadConspiracyMobile from '../../assets/img/text-headers/widespread-conspiracy-mobile.svg'

const Section = styled.section`
  position: relative;

  width: 100%;
  text-align: center;
  display: grid;
  justify-items: center;

  ${media.mobile} {
    white-space: pre;
    margin: 50px auto 170px auto;
  }
  ${media.tablet} {
    margin: 120px auto 200px auto;
    max-width: 650px;
  }
  ${media.desktop} {
    white-space: normal;
    margin: 0 auto;
    height: 400px;
    max-width: 900px;
    grid-row-gap: 20px;
  }
`

const HeaderDesktop = styled.img`
  margin: 0 auto;
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const HeaderMobile = styled.img`
  margin: 0 auto;
  ${media.tablet} {
    display: none;
  }
`

const ArrowImg = styled.img`
  max-width: 1132px;
  min-height: 225px;

  position: absolute;
  left: 48%;
  transform: translate(-50%, 0);
  z-index: -1;

  ${media.mobile} {
    top: 79%;
    width: 120vw;
  }
  ${media.tablet} {
    top: 90%;
    width: 90vw;
  }
  ${media.desktop} {
    top: 35%;
    width: 96vw;
  }
`

export default () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.from('.conspiracy__header', {
      scrollTrigger: '.conspiracy__header',
      delay: 0.25,
      opacity: 0,
      duration: 2,
    })
  }, [])

  return (
    <Section>
      <HeaderDesktop
        src={WideSpreadConspiracyDesktop}
        alt="Tricking you into being healthy"
        className="conspiracy__header"
      />
      <HeaderMobile
        src={WideSpreadConspiracyMobile}
        alt="Tricking you into being healthy"
        className="conspiracy__header"
      />
      <ArrowImg src={Arrow} aria-hidden />
    </Section>
  )
}
