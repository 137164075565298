import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Img from 'gatsby-image'

import { media } from '../../styles/util'

import TextHeader from '../../assets/img/text-headers/grummies-are-good-for-you.svg'

const float = keyframes`
  50% { transform: translate(3px, 6px) }
`

const Section = styled.section`
  position: relative;

  width: 100%;
  display: grid;
  justify-content: center;

  ${media.mobile} {
    height: 320px;
    padding-top: 100px;
    overflow-x: hidden;
  }
  ${media.tablet} {
    height: 600px;
    padding-top: 160px;
  }
  ${media.desktop} {
    height: 553px;
    padding-top: 100px;
    overflow-x: initial;
    max-width: 1400px;
    margin: 0 auto;
  }
`

const HeaderImg = styled.img`
  width: 100%;

  ${media.mobile} {
    max-width: 90vw;
  }
  ${media.desktop} {
    max-width: 835px;
  }
`

const RoundPurpleImg = styled.div`
  position: absolute;
  left: 10vw;
  top: 2vh;

  z-index: 2;
  animation: ${float} 5s ease-out infinite;

  ${media.mobile} {
    width: 36px;
    height: 36px;
  }
  ${media.tablet} {
    width: 45px;
    height: auto;
  }
`

const RoundBlueImg = styled(RoundPurpleImg)`
  z-index: 3;

  right: 10vw;
  top: auto;
  left: auto;
  animation-duration: 3s;

  ${media.mobile} {
    bottom: 5%;
    width: 45px;
    height: 45px;
  }
  ${media.tablet} {
    bottom: 10%;
    width: 60px;
    height: 60px;
  }
  ${media.desktop} {
    bottom: 15%;
  }
`

const LargeGreenImg = styled(RoundPurpleImg)`
  top: auto;
  animation-duration: 3s;

  ${media.mobile} {
    bottom: 10%;
    left: -10%;
    width: 200px;
    height: auto;
  }
  ${media.tablet} {
    bottom: 17%;
    left: -10%;
    width: 370px;
    height: auto;
  }
  ${media.desktop} {
    bottom: 8%;
    left: 9%;
    width: 37%;
    max-width: 448px;
  }
`

const LargePurpleImg = styled(RoundPurpleImg)`
  max-width: 286px;

  animation-duration: 5s;
  ${media.mobile} {
    width: 32%;
    height: auto;
    top: 5%;
    left: 35%;
  }
  ${media.tablet} {
    top: 3%;
    left: 35%;
  }
  ${media.desktop} {
    width: 40%;
    height: auto;
    top: -8%;
    left: 42%;
  }
`

const LargeRedImg = styled(RoundPurpleImg)`
  top: auto;
  left: auto;
  animation-duration: 4s;

  ${media.mobile} {
    bottom: 11%;
    width: 160px;
    right: -10%;
    height: auto;
  }
  ${media.tablet} {
    bottom: 15%;
    right: -5%;
    width: 300px;
    height: auto;
  }
  ${media.desktop} {
    bottom: 15%;
    right: 8%;
    width: 28.6vw;
    max-width: 300px;
    height: auto;
  }
`

const query = graphql`
  query {
    roundPurpleBlob: file(relativePath: { regex: "/homepage/grood-for-you/round-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 45) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    roundBlueBlob: file(relativePath: { regex: "/homepage/grood-for-you/round-blue.png/" }) {
      childImageSharp {
        fluid(maxWidth: 45) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largeGreenBlob: file(relativePath: { regex: "/homepage/grood-for-you/large-green.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largePurpleBlob: file(relativePath: { regex: "/homepage/grood-for-you/large-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largeRedBlob: file(relativePath: { regex: "/homepage/grood-for-you/large-red.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.from('.grood-for-you__blob', {
      delay: 0.25,
      scrollTrigger: '.grood-for-you__blob',
      transform: 'scale(0.2)',
      stagger: 0.1,
      duration: 0.25,
      opacity: 0,
    })

    gsap.from('.grood-for-you__header-image', {
      delay: 0.25,
      scrollTrigger: '.grood-for-you__header-image',
      opacity: 0,
    })
  }, [])

  return (
    <Section>
      <HeaderImg
        src={TextHeader}
        alt="Grummies are grood for you"
        className="grood-for-you__header-image"
      />

      <RoundPurpleImg>
        <Img fluid={data.roundPurpleBlob.childImageSharp.fluid} aria-hidden />
      </RoundPurpleImg>
      <RoundBlueImg>
        <Img fluid={data.roundBlueBlob.childImageSharp.fluid} aria-hidden />
      </RoundBlueImg>
      <LargeGreenImg className="grood-for-you__blob">
        <Img fluid={data.largeGreenBlob.childImageSharp.fluid} aria-hidden />
      </LargeGreenImg>
      <LargePurpleImg className="grood-for-you__blob">
        <Img fluid={data.largePurpleBlob.childImageSharp.fluid} aria-hidden />
      </LargePurpleImg>
      <LargeRedImg className="grood-for-you__blob">
        <Img fluid={data.largeRedBlob.childImageSharp.fluid} aria-hidden />
      </LargeRedImg>
    </Section>
  )
}
